<template>
  <v-container fill-height id="tc_container">
      <v-card  style="margin:auto; width:60%; smax-height:600px  overflow-y: scroll;
" fill-width >
        <v-card-title class="text-h5 white--text primary">
          CONSULTING SERVICES AGREEMENT
        </v-card-title>
            <v-card-text class="terms">
                <div v-html=terms class="mx-2 mt-2" >
                            
                    </div>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
     
               color="white"
                class="ms-auto btn-info bg-gradient-primary border-radius-md mt-4
                      placeholder-lighter
                      text-xs
                      text-white
                      py-5
                      px-6
                      mt-6
                      mb-2
                      ms-2
                "

            @click="acceptConditions()"
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>

          <v-snackbar
        v-model="snackbar"
        :color="color"
        :top='true'
      >
      <div v-html = snackbarMsg> </div>
      
      </v-snackbar>

  </v-container>

</template>
<style>

#app{
    background-size: cover !important;
    color: #F5F5F5;
}
.terms{
    height: 600px;
    overflow-y: scroll;
    text-align: justify !important;
}
</style>

<script>

export default {

  data: function () {
    return {
   
      inviteCode: "",
      terms: "",
      snackbarMsg: '',
      snackbar: false,
      color: 'light-purple',
    }
  },
 
  created: async function (){

     this.inviteCode = this.$route.params.inviteCode
     //load t&c text from DB
      this.$store.dispatch('getTerms')
              .then( (response) => {

                               this.terms = response.data.data.tc

              }  )
              .catch(() => {

})

  },
  methods: {

        acceptConditions (){

            //send request to api to enable account
            this.$store.dispatch('acceptTerms', {inviteCode: this.inviteCode})
              .then( (response) => {

                            //snackbar msg
                    this.snackbarMsg = response.data.msg
                    this.snackbar = true
              }  )
              .catch((e) => { 

                    this.snackbarMsg = e.response.data.msg
                    this.snackbar = true
              })

      }
  }

 
}
</script>
